import { IHttpApiConfiguration } from '@locumsnest/core';

import { environment } from '../../environments/environment';
import { IProcessStatus } from '../interfaces/process-status';

const zendeskProxy = 'zendesk/redirect';
export const zendeskProxyApi = `${zendeskProxy}/api/v2/`;
export const ephemeralOrigins = environment.ephemeralOrigins;
export const isEphemeralOrigin = () => ephemeralOrigins.some((o) => o === window.origin);
export const ENDPOINT_CONFIG = {
  zendeskProxy,
  account: 'accounts/',
  adjacentApplications: 'hospitals/applications/adjacent/',
  adjacentBids: 'external_staffing/external_staffing_candidate_bids/adjacent/',
  agencyConfirm: 'hospitals/time_sheets/${id}/agency_confirm/',
  agencyRevertConfirm: 'hospitals/time_sheets/${id}/agency_revert_confirm/',
  application: 'hospitals/applications/',
  applicationHistory: 'hospitals/profiles/${profileId}/application_history/',
  applicationStatus: 'attributes/job_application_statuses/',
  approve: 'hospitals/applications/${id}/approve/',
  approveBid: 'external_staffing/external_staffing_candidate_bids/${id}/approve/',
  approvedRate: 'jobs/approved_rates/',
  approveReviewRequest: 'control/trust_configuration_category_review_requests/${id}/approve/',
  authGroup: 'hospitals/auth_groups/',
  authGroupCurrent: 'hospitals/auth_groups/my/',
  bankHoliday: 'configurations/bank_holidays/',
  breakTime: 'working_time_regulations/break_time/',
  breakTimeDeductionPolicy: 'attributes/break_deduction_policies/',
  cancelBid: 'external_staffing/external_staffing_candidate_bids/${id}/cancel/',
  cascadeBulkAuthorize: 'external_staffing/staffing_cascades/bulk_authorize/',
  cascadeBulkCancel: 'external_staffing/staffing_cascades/bulk_cancel_tier/',
  cascadeBulkCascade: 'external_staffing/staffing_cascades/bulk_cascade/',
  cascadeBulkReject: 'external_staffing/staffing_cascades/bulk_reject/',
  cascadeStats: 'external_staffing/staffing_cascades/stats/',
  certificate: 'hospitals/profiles/${profileId}/certificates',
  certificateType: 'profiles/certificate_types/',
  clearOutOfSync: 'external_providers/job_listings/${id}/clear_out_of_sync/',
  cloneCertificateToHospital:
    'hospitals/profiles/${profileId}/safebox_certificates/clone_to_hospital/',
  cloneReferenceToHospital: 'hospitals/profiles/${profileId}/safebox_references/clone_to_hospital/',
  columnMappings: 'link/templates/${id}/column_mappings/',
  completedTrustOnboardingStep: 'onboarding/completed_trust_onboarding_steps/',
  complianceMessage: 'hospitals/profiles/${profileId}/compliance_messages/',
  conciergeRequests: 'intelligence/requests/',
  configurationCategory: 'control/configuration_categories/',
  configurationCategories: 'control/configuration_categories/',
  configurationCategoryFiles: 'control/configuration_category_files/',
  configuration: 'control/configurations/',
  configurationIcons: 'control/configuration_icons/',
  controlFiles: 'control/file/',
  controlFilesBulkCreate: 'control/file/bulk_create/',
  controlRecentActivity: 'recent_activity/for_trust_configuration_category/${id}/',
  conversationProfiles: 'messaging/conversation_profiles/',
  copyFromCertificate:
    'hospitals/profiles/${profileId}/safebox_certificates/copy_from_certificate/',
  copyFromReference: 'hospitals/profiles/${profileId}/safebox_references/copy_from_reference/',
  costCentreNumber: 'jobs/cost_centre_number/',
  createSingle: 'hospitals/staff_bank_memberships/create_single/',
  dailyBidSummaries: 'external_staffing/daily_bid_summaries/',
  dailyShiftSummaries: 'hospitals/dashboard/daily_shift_summaries/',
  decline: 'hospitals/applications/${id}/decline/',
  declineAllAndUnpublish: 'jobs/job_listings/${id}/decline_all_and_unpublish/',
  declineApplicationReason: 'tags/decline_application_reason/',
  declineReviewRequest: 'control/trust_configuration_category_review_requests/${id}/decline/',
  deleteFile: 'jobs/staff_bank_delete_files/',
  deleteSingle: 'hospitals/staff_bank_memberships/delete_single/',
  deleteStaffbank: 'hospitals/staff_bank_memberships/delete/',
  emailFrequency: 'attributes/email_frequencies/',
  escalate: 'hospitals/job_listings/escalate/',
  escalateForAgencies: 'hospitals/job_listings/escalate_for_agencies/',
  exportApplications: 'hospitals/applications/export/',
  exportBids: 'external_staffing/external_staffing_candidate_bids/export/',
  exportOfficerData: 'hospitals/hospital_officers/export_data/',
  exportShifts: 'jobs/job_listings/export/',
  exportStaffBank: 'hospitals/staff_bank_memberships/export/',
  exportStaffBankUnavailability: 'hospitals/staffbank_unavailability/export/',
  exportTimesheets: 'hospitals/time_sheets/export/',
  externalFiles: 'link/files/',
  externalJobListing: 'hospitals/external_job_listings/',
  externalStaffingApprovedRate: 'external_staffing/external_staffing_approved_rates/',
  externalStaffingCandidateBid: 'external_staffing/external_staffing_candidate_bids/',
  externalStaffingCandidateBidBulkCreate:
    'external_staffing/external_staffing_candidate_bids/bulk_create/',
  externalStaffingCandidateBidStatus: 'external_staffing/external_staffing_candidate_bid_statuses/',
  externalStaffingCandidateDocument: 'external_staffing/external_staffing_candidate_documents/',
  externalStaffingCandidateDocumentType:
    'external_staffing/external_staffing_candidate_document_types/',
  externalStaffingProvider: 'external_staffing/external_staffing_providers/',
  externalStaffingProviderOfficer: 'external_staffing/external_staffing_provider_officers/',
  externalStaffingProviderTier: 'external_staffing/external_staffing_provider_tiers/',
  flatRateTimeSheetMaxDuration: 'working_time_regulations/flat_rate_timesheet_max_duration/',
  gmcStatus: 'attributes/gmc_statuses/',
  grade: 'jobs/grades/',
  gradeMappings: 'link/templates/${id}/grade_mappings/',
  gradeRate: 'jobs/grade_rates/',
  hospital: 'hospitals/hospitals/',
  hospitalCertificates: 'hospitals/certificates/',
  hospitalOfficer: 'hospitals/hospital_officers/',
  hospitalOfficerSite: 'hospitals/hospital_officer_sites/my/',
  hospitalProfessionConfiguration: 'jobs/hospital_profession_configurations/',
  hospitalProfileLabel: 'jobs/hospital_profile_labels/',
  hospitalProfileTag: 'jobs/hospital_profile_tags/',
  hospitalSettings: 'hospitals/hospitals/${hospitalId}/configuration/',
  hospitalSpecialty: 'hospitals/hospital_specialties/',
  hospitalStaffBankUnavailability: 'hospitals/staffbank_unavailability/',
  hospitalStaffBankUnavailabilityReason: 'hospitals/staffbank_unavailability_reasons/my/',
  hospitalVacancyReason: 'hospitals/hospital_vacancy_reasons/my/',
  hourlyTimeSheetMaxDuration: 'working_time_regulations/hourly_timesheet_max_duration/',
  importBid: 'external_staffing/external_staffing_candidate_bids/import_bid/',
  importFile: 'jobs/staff_bank_import_files/',
  integration: 'link/integrations/',
  jobListing: 'jobs/job_listings/',
  jobListingBookingStats: 'jobs/job_listing_booking_stats/',
  jobListingTemplate: 'hospitals/job_listings_templates/',
  jobListingType: 'attributes/job_listing_types/',
  jobPosition: 'hospitals/profiles/${profileId}/job_positions/',
  linkColumnChoice: 'link/column_choices/',
  linkTemplate: 'link/templates/',
  linkType: 'link/file_types/',
  listingConversations: 'messaging/listing_conversations/',
  listingConversationsBulk: 'messaging/listing_conversations/bulk_create/',
  listingsBulkCreate: 'jobs/job_listings/bulk_create/',
  listingIdentifierStats: 'jobs/job_listings/listing_identifier_stats/',
  lockBid: 'external_staffing/external_staffing_candidate_bids/${id}/lock/',
  lockRates: 'jobs/job_listings/lock_rates/',
  lockTimesheetRates: 'hospitals/time_sheets/${id}/lock_rates/',
  lockTimesheetRatesBulk: 'hospitals/time_sheets/bulk_lock_rates/',
  locumsnestConfiguration: 'configurations/locumsnest_configuration/',
  login: 'session_auth/login/',
  logout: 'session_auth/logout/',
  markAsRead: 'alerts/passport_plus_alerts/${id}/mark_as_read/',
  markCancellationAsSeen: 'hospitals/applications/${id}/mark_cancellation_as_seen/',
  markCertificateAsActioned: 'hospitals/certificates/${id}/mark_as_actioned/',
  passportAlertType: 'attributes/passport_plus_alert_types/',
  passportAlerts: 'alerts/passport_plus_alerts/',
  passwordChange: 'accounts/password/change/',
  payRateType: 'jobs/time_based_pay_rate_types/',
  pensionCategory: 'attributes/pension_categories/',
  pensionConfiguration: 'invoices/pension_configurations/',
  permission: 'hospitals/permissions/',
  permissionCurrent: 'hospitals/permissions/my/',
  preferredPassportProfession: 'officer_filters/my/preferred_passport_professions/',
  preferredProfession: 'officer_filters/my/preferred_professions/',
  preferredProfessionSpecialty: 'officer_filters/my/preferred_profession_specialties/',
  preferredRoster: 'officer_filters/my/preferred_rosters/',
  preferredSite: 'officer_filters/my/preferred_sites/',
  preferredSpecialtyBulkCreate: 'officer_filters/my/preferred_profession_specialties/bulk_create/',
  preferredSpecialtyBulkDelete: 'officer_filters/my/preferred_profession_specialties/clear/',
  preferredSpecialtyCategory: 'officer_filters/my/preferred_specialty_categories/',
  preferredWard: 'officer_filters/my/preferred_wards/',
  profession: 'professions/professions/',
  professionMappings: 'link/templates/${id}/profession_mappings/',
  professionSpecialty: 'professions/profession_specialties/',
  profile: 'hospitals/profiles/',
  profileAssignmentNumbers: 'payroll/assignment_number/',
  profileFlag: 'hospitals/profile_flag/',
  profileHospitalProfileLabel: 'profiles/profile_hospital_profile_labels/',
  profileHospitalProfileTag: 'profiles/profile_hospital_profile_tags/',
  profileLabel: 'tags/profile_labels/',
  profileNote: 'hospitals/profile_notes/',
  profileNoteFlag: 'hospitals/profile_notes/${id}/flag/',
  profileNoteUnFlag: 'hospitals/profile_notes/${id}/un_flag/',
  profileSearch: 'profiles/search/profile_search/',
  profileBankDetails: 'profiles/bank_details/',
  profileStatistics: 'hospitals/profiles/${profileId}/stats/',
  profileTag: 'tags/profile_tags/',
  profileUnregisteredSearch: 'profiles/search/unregistered_search/',
  promo: 'surveys/promos/',
  promoCategory: 'surveys/promo_categories/',
  publish: 'jobs/job_listings/publish/',
  publishTrustConfigurationCategoryDetails:
    'control/trust_configuration_category_details/${id}/publish/',
  questionResponse: 'surveys/responses/',
  rateViolationReason: 'tags/rate_violation_reasons/',
  recoveryConfirmation: 'accounts/password/reset/confirm/',
  recoveryRequest: 'accounts/password/reset/',
  recentActivityShift: 'recent_activity/for_shift/${id}/',
  recentActivityTimesheet: 'recent_activity/for_timesheet/${id}/',
  reference: 'hospitals/profiles/${profileId}/references',
  rejectBid: 'external_staffing/external_staffing_candidate_bids/${id}/reject/',
  revokeInvitation: 'messaging/listing_conversations/${id}/revoke_invitation/',
  bulkRevokeInvitation: 'messaging/listing_conversations/bulk_revoke/',
  bulkReInvite: 'messaging/listing_conversations/bulk_re_invite/',
  reInvite: 'messaging/listing_conversations/${id}/re_invite/',
  removeEscalation: 'hospitals/job_listings/remove_escalation/',
  removeEscalationForAgencies: 'hospitals/job_listings/remove_escalation_for_agencies/',
  requestTier: 'external_staffing/staffing_cascades/${id}/request_tier/',
  cancelTier: 'external_staffing/staffing_cascades/${id}/cancel_tier/',
  redoTrustConfigurationCategoryDetails: 'control/trust_configuration_category_details/${id}/redo/',
  revalidationStatus: 'attributes/revalidation_statuses/',
  roster: 'jobs/rosters/',
  safeboxCertificate: 'hospitals/profiles/${profileId}/safebox_certificates/',
  safeboxReference: 'hospitals/profiles/${profileId}/safebox_references/',
  sector: 'sectors/sectors/',
  shiftMaxDuration: 'working_time_regulations/shift_max_duration/',
  signedTerm: 'external_staffing/signed_terms/',
  site: 'jobs/sites/',
  siteCostCentreNumber: 'jobs/sites/${sideId}/cost_centre_number/',
  specialty: 'hospitals/specialties/',
  specialtyCategory: 'hospitals/specialty_categories/',
  specialtyMappings: 'link/templates/${id}/specialty_mappings/',
  staffBankCollaborationGroup: 'hospitals/staff_bank_collaboration_groups/',
  staffBankMembership: 'hospitals/staff_bank_memberships/',
  staffBankMembershipRequest: 'hospitals/staff_bank_membership_requests/',
  staffBankMembershipRequestStatus: 'tags/staff_bank_membership_request_status/',
  staffBankUnavailabilityReason: 'tags/staffbank_unavailability_reason',
  staffbankStatistics: 'hospitals/staff_bank_memberships/stats/',
  staffingCascade: 'external_staffing/staffing_cascades/',
  staffingCascadeStatus: 'external_staffing/staffing_cascade_statuses/',
  staffingCascadeTimeWindow: 'external_staffing/staffing_cascade_time_windows/',
  submitForAuthorization: 'jobs/job_listings/submit_for_authorization/',
  survey: 'surveys/surveys/',
  tableauViews: 'intelligence/tableau_views/',
  tempUpload: 'files/file_uploads/',
  timeSheet: 'hospitals/time_sheets/',
  timeSheetChargeCode: 'hospitals/time_sheet_charge_codes/',
  timeSheetComment: 'hospitals/time_sheet_comments/',
  timeSheetStatus: 'hospitals/time_sheet_statuses/',
  trustApprovedExternalStaffingProviderFee:
    'external_staffing/trust_approved_external_staffing_provider_fees/',
  trustConfigurationCategory: 'control/trust_configuration_categories/',
  trustConfigurationCategoryDetails: 'control/trust_configuration_category_details/',
  trustConfigurationCategoryDetailsFiles: 'control/trust_configuration_category_detail_files/',
  trustConfigurationCategoryDetailsFilesBulkCreate:
    'control/trust_configuration_category_detail_files/bulk_create/',
  trustConfigurationCategoryDetailsReview: 'control/trust_configuration_category_detail_reviews/',
  trustConfigurationCategoryDetailsReviewBulkCreate:
    'control/trust_configuration_category_detail_reviews/bulk_create/',
  trustConfigurationCategoryNote: 'notes/trust_configuration_category_notes/',
  trustConfigurationCategoryReviewRequest: 'control/trust_configuration_category_review_requests/',
  trustExternalStaffingProviderTier: 'external_staffing/trust_external_staffing_provider_tiers/',
  trustOnboardingSection: 'onboarding/trust_onboarding_sections/',
  trustOnboardingStep: 'onboarding/trust_onboarding_steps/',
  undoTrustConfigurationCategoryDetails: 'control/trust_configuration_category_details/${id}/undo/',
  unlockBid: 'external_staffing/external_staffing_candidate_bids/${id}/unlock/',
  unlockRates: 'jobs/job_listings/unlock_rates/',
  unlockTimesheetRates: 'hospitals/time_sheets/${id}/unlock_rates/',
  unlockTimesheetRatesBulk: 'hospitals/time_sheets/bulk_unlock_rates/',
  unpublish: 'jobs/job_listings/unpublish/',
  bulkUnpublish: 'jobs/job_listings/bulk_unpublish/',
  updateStatus: 'hospitals/staff_bank_membership_requests/${id}/update_status/',
  user: 'hospitals/users/',
  vacancyReason: 'attributes/vacancy_reason/',
  vacancyReasonMappings: 'link/templates/${id}/vacancy_reason_mappings/',
  video: 'surveys/community_videos/',
  ward: 'jobs/wards/',
  withdrawBid: 'external_staffing/external_staffing_candidate_bids/${id}/withdraw/',
  workingTimeRegulations: 'working_time_regulations/violation_reports/',
  zendesk: 'zendesk/',
  zendeskNewJwt: 'zendesk/new_jwt/',
  zendeskFaq: zendeskProxyApi + 'help_center/articles/search.json',
  zendeskFaqSections: zendeskProxyApi + 'help_center/en-us/categories/${categoryId}/sections/',
  zendeskFaqSectionArticles: zendeskProxyApi + 'help_center/en-us/sections/${sectionId}/articles/',
  zendeskFaqAllArticles: zendeskProxyApi + 'help_center/en-us/categories/${categoryId}/articles/',
  zendeskVoteDown: zendeskProxyApi + 'help_center/articles/${articleId}/down/',
  zendeskVoteUp: zendeskProxyApi + 'help_center/articles/${articleId}/up/',
  listingNotes: 'notes/listing_notes/',
  listingNotesBulkCreate: 'notes/listing_notes/bulk_create/',
  zendeskRequests: zendeskProxyApi + 'requests',
  zendeskRequestsAddComment: zendeskProxyApi + 'requests/',
  zendeskRequestComments: zendeskProxyApi + 'requests/${requestId}/comments',
  zendeskFileUpload: zendeskProxyApi + 'uploads/',
  timesheetNotes: 'notes/timesheet_notes/',
  pushNotificationAddToken: 'push_notifications/tokens/add/',
  staffbankRejectionReason: 'tags/staffbank_rejection_reasons/',
  hospitalStaffbankRejectionReason: 'hospitals/hospital_staffbank_rejection_reasons/',
  staffbankMembershipReject: 'hospitals/staff_bank_membership_requests/${id}/reject/',
  listingConversationTypes: 'attributes/listing_conversation_types/',
  filterViews: 'filters/filter_views/',
  tag: 'hospitals/officer_tags/',
  tagsBulkDelete: 'hospitals/officer_tags/bulk_delete',
  jobListingTags: 'jobs/tags/',
  jobListingTagsBulkDelete: 'jobs/tags/bulk_delete',
  bidTimesheetInvoices: 'invoices/bid_timesheet_invoices/',
  deProfileAssignmentNumber: 'profiles/de_profiles_assignment_numbers/',
  externalStaffingProviderHospitalProfile:
    'external_staffing/external_staffing_provider_hospital_profile/',
  profilePaymentDetails: 'profiles/payment_details/',
  approveRateAuthoriser: 'jobs/approve_rate_authorisers/',
  demandRequest: 'jobs/demand_requests/',
  demandRequestApprove: 'jobs/demand_requests/${id}/approve/',
  demandRequestReject: 'jobs/demand_requests/${id}/reject/',
  agencyEscalationRequest: 'escalations/external_escalation_requests/',
  externalApprovedRateEscalationLevel: 'escalations/external_escalation_levels/',
  unlockEscalationBid: 'escalations/external_escalation_requests/${id}/approve/',
  rejectEscalationBid: 'escalations/external_escalation_requests/${id}/reject/',
};

export const DEFAULT_CURRENCY = 'GBP';
export const DEFAULT_LOCALE_ID = 'en-GB';

export const PROFILE_RESOURCE_TYPE_VERBOSE_NAMES = {
  DentalCareProfessionalProfile: 'GDC',
  AHPProfile: 'HCPC',
  MedicProfile: 'GMC',
  PharmacistProfile: 'GPHC',
  NurseProfile: 'NMC',
};

export type LocumsNestEndpointConfig = typeof ENDPOINT_CONFIG;

export const HTTP_API_CONFIGURATION: IHttpApiConfiguration<LocumsNestEndpointConfig> = {
  baseUrl: `${environment.api}api/`,
  version: 'v25/',
  endpoints: ENDPOINT_CONFIG,
};

export const JOB_LISTING_FORM_VERSION: { currentVersion: 'v2' } = {
  currentVersion: 'v2',
};

export const UUIDV5_NAMESPACE = {
  timeFragment: '1b621a64-40d5-491e-99b0-da01ff1f3341',
};

export const LOADING_PHRASES: string[] = [
  'building nests',
  'ruffling feathers',
  'nursing owlets',
  'sharpening beaks',
  'stretching wings',
  'turning heads',
];

export const SECTOR_CODES = {
  PRIMARY_CARE: 'PRIMARY_CARE',
  SECONDARY_CARE: 'SECONDARY_CARE',
  INDEPENDENT: 'INDEPENDENT_SECTOR',
  VET: 'VETERINARY_SERVICES',
};

export const PRODUCT_CODES = {
  MATCH: 'locumsnest',
  INTELLIGENCE: 'intelligence',
  COMMUNITY: 'community',
  PASSPORT_PLUS: 'passport',
  LINK: 'link',
  ROTA: 'rota',
  AGENCY: 'agency',
  CONTROL: 'control',
} as const;

export const PROCESS_STATUSES: IProcessStatus[] = [
  {
    id: 0,
    name: 'PENDING',
    display: 'Pending',
  },
  {
    id: 1,
    name: 'PROCESSING',
    display: 'Processing',
  },
  {
    id: 2,
    name: 'PROCESSED',
    display: 'Processed',
  },
  {
    id: 3,
    name: 'FAILED',
    display: 'Failed',
  },
];

export const HOURLY_MIN_WAGE = 8.91;

export const WEEKDAYS = {
  BANK_HOLIDAYS: 'Bank Holiday',
  WEEKEND: 'Weekend (Sat-Sun)',
  WEEKDAY: 'Weekday (Mon-Fri)',
  ANY_DAY: 'Any Day',
};

export function getServerName(): string {
  const href = window.location.href;

  if (href.includes('localhost')) {
    return 'LOCALHOST';
  }
  if (href.includes('dev')) {
    return 'DEV';
  }
  if (href.includes('qa')) {
    return 'QA';
  }
  if (href.includes('demo')) {
    return 'DEMO';
  }
  if (href.includes('preprod')) {
    return 'PRE-PROD';
  }
  return '';
}

export const termsAndConditionsUrl =
  'https://locumsnest.co.uk/locums-nest-platform-agreement-agency';
