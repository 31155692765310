import { setUser } from '@sentry/angular-ivy';
import { get, isNil } from 'lodash-es';
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  ObservedValueOf,
  OperatorFunction,
  pipe,
  tap,
  throwError,
} from 'rxjs';

type NonEmptyArray<T> = [T, ...T[]];

const noopLog = (v: any) => '';
const getCollectionKeySet = <T>(c: Array<T>, key: keyof T) => new Set(c.map((item) => item[key]));

const includesAll = (c1: unknown[], c2: unknown[]) => {
  const set = new Set(c1);
  return c2.every((x) => set.has(x));
};
const includesAllKeys = (c1: any[], c2: any[], key: any) => {
  const set = getCollectionKeySet(c1, key);
  return c2.every((x) => set.has(x[key]));
};
const collectionEquals = (c1: any[], c2: any[]) => c1.length === c2.length && includesAll(c1, c2);
const collectionKeysEqual = (c1: any[], c2: any[], key) =>
  c1.length === c2.length && includesAllKeys(c1, c2, key);
export const filterNonEmpty: <T>(source: Observable<T[]>) => Observable<NonEmptyArray<T>> = pipe(
  filter((x): x is NonEmptyArray<any> => !!x?.length),
);

export function filterNotNil<T>(): OperatorFunction<T, NonNullable<T>> {
  return filter((v): v is NonNullable<T> => !isNil(v));
}

export const distinctCollectionUntilChangedByKey = <T>(key: keyof T) =>
  distinctUntilChanged((c1: T[], c2: T[]) => collectionKeysEqual(c1, c2, key));

export const distinctCollectionUntilChangedByValues = <T>() =>
  distinctUntilChanged((c1: T[], c2: T[]) => collectionEquals(c1, c2));
export const distinctCollectionUntilChangedByLength = <T>() =>
  distinctUntilChanged((c1: T[], c2: T[]) => c1.length === c2.length);
export const selectCollectionCount: <T>(source: Observable<Array<T>>) => Observable<number> = pipe(
  map((collection) => collection.length),
  distinctUntilChanged(),
);

// cspell:disable-next-line
export const traceCalls = <T>(flag: string, log = noopLog) => {
  const stack = new Error().stack;
  // eslint-disable-next-line no-console
  return pipe(tap<T>((x) => console.log(`${flag}\n ${log(x)} \n ${stack}`)));
};

export const toSessionUser = (emailPath = 'payload.user.email', idPath = 'payload.user.id') =>
  pipe(
    tap((message: any) => {
      const id = String(get(message, idPath, ''));
      const email = get(message, emailPath, '');
      if (id.length || email.length) {
        setUser({
          id: id || null,
          email: email || null,
          ip_address: '{{auto}}',
        });
      }
    }),
  );

export function silenceLastPageReachError<T>(): OperatorFunction<T, T | ObservedValueOf<never>> {
  return catchError((error: any) => {
    if (error?.error?.detail === 'Invalid page.') {
      // eslint-disable-next-line no-console
      console.log(`last page reached`);
      return EMPTY;
    }
    return throwError(() => error);
  });
}
